<template>
  <div>
    <vb-multiple-edit-terminal
      v-if="multipleEditModalVisible"
      :key="multipleEditModalKey"
      :terminal-ids="selectedRowKeys"
      :stores="stores"
      @reload="reload()"
      @hide-modal="hideMultipleEditModal"
    />
    <div class="row">
      <div class="col-md-3">
        <a-select
          v-model:value="brandId"
          :options="brands"
          :option-filter-prop="'label'"
          placeholder="Выберите бренд"
          style="width: 100%"
          @change="searchBrand"
          show-search
          allow-clear
        />
      </div>
      <div class="col-md-3">
        <a-select
          v-model:value="storeId"
          :options="stores"
          :option-filter-prop="'label'"
          placeholder="Выберите филиал"
          style="width: 100%"
          @change="searchStore"
          show-search
          allow-clear
        />
      </div>
      <div class="col-md-3">
        <a-input
          v-model:value="searchInput"
          placeholder="Введите ID или DeviceID"
          style="width: 100%"
          @change="search"
          show-search
          allow-clear
        />
      </div>
      <div class="col-md-3 text-right">
        <a-popconfirm
          v-if="storeId"
          placement="leftTop"
          title="Вы уверены что хотите добавить новую кассу?"
          @confirm="addTerminal"
          ok-text="Да"
          cancel-text="Нет"
        >
          <a-button v-if="['admin', 'brandmanager'].includes(user.role)" class="editable-add-btn" style="margin-bottom: 16px">Добавить кассу</a-button>
        </a-popconfirm>
        <a-popconfirm
          v-else
          placement="leftTop"
          title="Сначала нужно выбрать филиал"
        >
          <a-button v-if="['admin', 'brandmanager'].includes(user.role)" style="margin-bottom: 16px">Добавить кассу</a-button>
        </a-popconfirm>
      </div>
    </div>
    <div class="clearfix">
      <a-dropdown
        class="my-2"
        v-if="selectedRowKeys.length"
      >
        <template #overlay>
          <a-menu>
            <a-menu-item key="1" @click="multipleUpdateModal" v-if="user.role === 'admin'">
              Редактировать
            </a-menu-item>
            <a-menu-item key="2" @click="createQr(selectedRowKeys)" v-if="user.role === 'admin'">
              Создать QR для быстрой оплаты
            </a-menu-item>
            <a-menu-item key="3" @click="createStoreQr(selectedRowKeys)" v-if="user.role === 'admin'">
              Создать QR для стр. официантов
            </a-menu-item>
          </a-menu>
        </template>
        <a-button type="secondary">
          Выберите действие
          <DownOutlined/>
        </a-button>
      </a-dropdown>
      <a-typography-text v-if="selectedRowKeys.length" secondary class="mx-3">
        (Выделено: {{ selectedRowKeys.length }} элементов)
      </a-typography-text>
      <a-button
        v-if="showSelectAllButton"
        @click="selectAll"
        type="link"
      >Выделить все ({{ total }})
      </a-button>
      <a-button
        v-if="selectedRowKeys.length"
        @click="clearSelection"
        type="text"
        class="text-red"
      >
        Отменить
      </a-button>
    </div>
    <a-table
      :columns="columns"
      :data-source="dataSource"
      :row-selection="rowSelection"
      :loading="loading"
      :pagination="false"
      :scroll="{ x: 1300 }"
      @change="tableChange"
      row-key="id"
    >
      <template #id="{ record} ">{{ record.id }}</template>
      <template #name="{ record }">
        <div v-if="editNameId === record.id">
          <div class="d-flex">
            <a-input
              @keydown.esc="editNameId = 0"
              ref="inputName"
              @pressEnter="updateColumn(record.id, record.name); editNameId = 0"
              v-model:value="record.name"
            />
            <a-button
              @click="updateColumn(record.id, record.name); editNameId = 0" type="link" shape="round"
              class="px-2">
              <template #icon>
                <SaveOutlined/>
              </template>
            </a-button>
          </div>
        </div>
        <div v-else>
          <div class="d-flex justify-content-between">
            <div @click="onEditNameId(record.id)" :style="{ lineHeight: '30px' }">{{ record.name }}</div>
            <a-button
              v-if="['admin', 'brandmanager'].includes(user.role)"
              @click="onEditNameId(record.id)"
              type="link"
              shape="round"
              class="px-2"
            >
              <template #icon>
                <EditOutlined/>
              </template>
            </a-button>
          </div>
        </div>
      </template>
      <template #store_id="{ record }">
        <a-spin v-if="editStoreLoading === record.id" />
        <div v-else>
          <div v-if="editStoreId !== record.id" class="d-flex justify-content-between">
            <div>{{ record.store?.name }}</div>
            <a-button
              v-if="['admin', 'brandmanager'].includes(user.role)"
              @click="onEditStore(record.id, record.store_id)"
              type="link"
              shape="round"
              class="px-2 py-0"
              style="height: 22px"
            >
              <template #icon>
                <EditOutlined/>
              </template>
            </a-button>
          </div>
          <div v-if="editStoreOptionsLoading === record.id" class="d-flex justify-content-between">
            <div>{{ record.store?.name }}</div>
            <a-spin class="px-2" size="small" />
          </div>
          <div class="d-flex justify-content-between" v-else>
            <a-select
              @change="updateStore($event, record)"
              v-model:value="editStoreData"
              class="edit-type-select"
              v-if="editStoreId === record.id"
              :options="editStoreOptions"
            />
            <a-button
              v-if="editStoreId === record.id"
              type="text"
              @click="editStoreId = 0"
              shape="circle"
              class="edit-type-close"
              style="padding: 0 !important"
            >
              <template #icon>
                <CloseCircleOutlined style="color: #a2a4c8" />
              </template>
            </a-button>
          </div>
        </div>
      </template>
      <template #bill_commission="{ record }">
        <div v-if="editBillCommissionId === record.id" class="d-flex justify-content-between">
          <a-input
            @keydown.esc="editBillCommissionId = 0"
            ref="inputBillCommission"
            @pressEnter="updateColumn(record.id, record.name, 'bill_commission', record.bill_commission); editBillCommissionId = 0"
            v-model:value="record.bill_commission"
            style="max-width: 80px;"
          />
          <a-button
            @click="updateColumn(record.id, record.name, 'bill_commission', record.bill_commission); editBillCommissionId = 0" type="link" shape="round"
            class="px-2">
            <template #icon>
              <SaveOutlined/>
            </template>
          </a-button>
        </div>
        <div v-else class="d-flex justify-content-between">
          <div @click="onEditBillCommmissionId(record.id)" :style="{ lineHeight: '30px' }">{{ record.bill_commission }}%</div>
          <a-button v-if="user.role === 'admin'" @click="onEditBillCommmissionId(record.id)" type="link" shape="round" class="px-2">
            <template #icon>
              <EditOutlined/>
            </template>
          </a-button>
        </div>
      </template>
      <template #bill_commission_up="{ record }">
        <div v-if="editBillCommissionUpId === record.id" class="d-flex justify-content-between">
          <a-input
            @keydown.esc="editBillCommissionUpId = 0"
            ref="inputBillCommissionUp"
            @pressEnter="updateColumn(record.id, record.name, 'bill_commission_up', record.bill_commission_up); editBillCommissionUpId = 0"
            v-model:value="record.bill_commission_up"
            style="max-width: 100px;"
          />
          <a-button
            @click="updateColumn(record.id, record.name, 'bill_commission_up', record.bill_commission_up); editBillCommissionUpId = 0" type="link" shape="round"
            class="px-2">
            <template #icon>
              <SaveOutlined/>
            </template>
          </a-button>
        </div>
        <div v-else class="d-flex justify-content-between">
          <div @click="onEditBillCommmissionUpId(record.id)" :style="{ lineHeight: '30px' }">{{ record.bill_commission_up }}%</div>
          <a-button v-if="user.role === 'admin'" @click="onEditBillCommmissionUpId(record.id)" type="link" shape="round" class="px-2">
            <template #icon>
              <EditOutlined/>
            </template>
          </a-button>
        </div>
      </template>
      <template #payze_commission="{ record }">
        <div v-if="editPayzeCommissionId === record.id" class="d-flex justify-content-between">
          <a-input
            @keydown.esc="editPayzeCommissionId = 0"
            ref="inputPayzeCommission"
            @pressEnter="updateColumn(record.id, record.name, 'payze_commission', record.payze_commission); editPayzeCommissionId = 0"
            v-model:value="record.payze_commission"
            style="max-width: 100px;"
          />
          <a-button
            @click="updateColumn(record.id, record.name, 'payze_commission', record.payze_commission); editPayzeCommissionId = 0" type="link" shape="round"
            class="px-2">
            <template #icon>
              <SaveOutlined/>
            </template>
          </a-button>
        </div>
        <div v-else class="d-flex justify-content-between">
          <div @click="onEditPayzeCommmissionId(record.id)" :style="{ lineHeight: '30px' }">{{ record.payze_commission }}%</div>
          <a-button v-if="user.role === 'admin'" @click="onEditPayzeCommmissionId(record.id)" type="link" shape="round" class="px-2">
            <template #icon>
              <EditOutlined/>
            </template>
          </a-button>
        </div>
      </template>
      <template #type="{ record }">
        <a-spin v-if="editTypeLoading === record.id" />
        <div v-else>
          <a-tag
            :color="record.type ? 'processing' : ''"
            class="cursor-pointer"
            v-if="editTypeId !== record.id"
            @click="onEditType(record.id, record.type)"
          >
            {{ getTypeName(record.type) }}
          </a-tag>
          <a-select
            @change="updateType($event, record)"
            v-model:value="editType"
            class="edit-type-select"
            v-if="editTypeId === record.id"
          >
            <a-select-option
              :value="null"
            >Не указано</a-select-option>
            <a-select-option value="cashbox">{{ getTypeName('cashbox') }}</a-select-option>
            <a-select-option value="delivery">{{ getTypeName('delivery') }}</a-select-option>
            <a-select-option value="static">{{ getTypeName('static') }}</a-select-option>
            <a-select-option value="aggregator">{{ getTypeName('aggregator') }}</a-select-option>
          </a-select>
          <a-button
            v-if="editTypeId === record.id"
            type="text"
            @click="editTypeId = 0"
            shape="circle"
            class="edit-type-close py-0"
          ><CloseCircleOutlined style="color: #a2a4c8" /></a-button>
        </div>
      </template>
<!--      <template #fiscalization="{ record }">-->
<!--        <a-tag-->
<!--          @click="updateColumn(record.id, record.name, 'fiscalization', 0)"-->
<!--          v-if="record['fiscalization']"-->
<!--          color="#108ee9"-->
<!--          class="cursor-pointer"-->
<!--        >Включена-->
<!--        </a-tag>-->
<!--        <a-tag-->
<!--          @click="updateColumn(record.id, record.name, 'fiscalization', 1)"-->
<!--          class="cursor-pointer"-->
<!--          v-else-->
<!--        >Выключена-->
<!--        </a-tag>-->
<!--      </template>-->
      <template #device_id="{ record }">
        <div v-if="editDeviceId === record.id" class="d-flex justify-content-between">
          <a-input
            @keydown.esc="editDeviceId = 0"
            ref="inputDevice"
            @pressEnter="updateColumn(record.id, record.name, 'device_id', record.device_id); editDeviceId = 0"
            v-model:value="record.device_id"
          />
          <a-button
            @click="updateColumn(record.id, record.name, 'device_id', record.device_id); editDeviceId = 0" type="link" shape="round"
            class="px-2">
            <template #icon>
              <SaveOutlined/>
            </template>
          </a-button>
        </div>
        <div v-else class="d-flex justify-content-between">
          <div @click="onEditDeviceId(record.id)" :style="{ lineHeight: '30px' }">{{ record.device_id }}</div>
          <a-button v-if="['admin', 'brandmanager'].includes(user.role)" @click="onEditDeviceId(record.id)" type="link" shape="round" class="px-2">
            <template #icon>
              <EditOutlined/>
            </template>
          </a-button>
        </div>
      </template>
      <template #qr="{ record }">
        <a
          v-if="record.qr"
          :href="record.qr"
          class="cursor-pointer"
          target="_blank"
        >
          <img :src="record.qr" alt="" width="30">
        </a>
        <a-button
          v-else
          href="#"
          @click.prevent="createQr([record.id])"
          type="link"
          size="small"
        >Создать QR</a-button>
      </template>
      <template #store_qr="{ record }">
        <a
          v-if="record['store_qr']"
          :href="record['store_qr']"
          class="cursor-pointer"
          target="_blank"
        >
          <img :src="record['store_qr']" alt="" width="30">
        </a>
        <a-button
          v-else
          href="#"
          @click.prevent="createStoreQr([record.id])"
          type="link"
          size="small"
        >Создать QR</a-button>
      </template>
      <template #date="{ record }">
        <span>
          {{ formattedDatetime(record['added_on']) }}
        </span>
        <span v-if="record.author">
          ({{ record.author }})
        </span>
      </template>
      <template #updated="{ record }">
        <span v-if="record['updated_on']">
          {{ formattedDatetime(record['updated_on']) }}
        </span>
        <span v-if="record.updated">
          ({{ record.updated }})
        </span>
      </template>
      <template #action="{ record }">
        <div class="w-100 text-right">
          <a-tooltip placement="topRight" class="mx-2">
            <template #title>Удалить кассу</template>
            <a v-if="user.role === 'admin'" @click="showDeleteModal(record.id)" class="float-right"><i class="fe fe-trash"></i></a>
          </a-tooltip>
        </div>
      </template>
    </a-table>
    <a-modal
      v-model:visible="deleteModal"
      @cancel="hideDeleteModal"
      :footer="null"
    >
      <a-typography-title :level="5">Вы уверены что хотите удалить кассу?</a-typography-title>
      <div class="footer overflow-hidden pt-2">
        <a-button @click="deleteTerminal" type="primary" class="float-right">Да</a-button>
        <a-button @click="hideDeleteModal" type="secondary" class="float-right mx-2">Нет</a-button>
      </div>
    </a-modal>
    <div class="mt-4 text-right">
      <a-pagination
        show-size-changer
        v-if="total > query['per-page'].value"
        v-model:pageSize="query['per-page'].value"
        v-model:current="query.page.value"
        :total="total"
      />
    </div>
  </div>
</template>

<script setup>
import {SaveOutlined, EditOutlined, CloseCircleOutlined, DownOutlined} from "@ant-design/icons-vue";
import {computed, nextTick, onMounted, reactive, ref, toRefs, unref, watch} from 'vue';
import {useRoute, useRouter} from "vue-router";
import {Modal, message, notification} from 'ant-design-vue';
import apiClient from "@/services/axios";
import {useStore} from "vuex";
import VbMultipleEditTerminal from "@/views/store/terminal/multiple-edit.vue";

let
  multipleEditModalVisible = ref(false),
  selectedRowKeys = ref([]),
  multipleEditModalKey = ref(0),
  brandId = ref(null),
  deleteModal = ref(false),
  deleteId = ref(0),
  brands = ref([]),
  storeId = ref(null),
  stores = ref([]),
  editNameId = ref(0),
  editDeviceId = ref(0),
  editBillCommissionId = ref(0),
  editPayzeCommissionId = ref(0),
  editBillCommissionUpId = ref(0),
  editTypeLoading = ref(0),
  editStoreLoading = ref(0),
  editStoreId = ref(0),
  editStoreData = ref(null),
  editStoreOptions = ref([]),
  editStoreOptionsLoading = ref(0),
  editTypeId = ref(0),
  editType = ref(null),
  dataSource = ref([]),
  loading = ref(false),
  inputName = ref("inputName"),
  inputDevice = ref("inputDevice"),
  inputBillCommission = ref("inputBillCommission"),
  inputPayzeCommission = ref("inputPayzeCommission"),
  inputBillCommissionUp = ref("inputBillCommissionUp"),
  showSelectAllButton = ref(false),
  timer = null,
  total = ref(0);

const router = useRouter(),
  store = useStore(),
  filters = computed(() => store.getters.filters),
  onSelectChange = changeableRowKeys => {
    selectedRowKeys.value = changeableRowKeys;
  },
  rowSelection = computed(() => {
    if (user.value.role !== 'admin') {
      return null
    }
    return {
      selectedRowKeys: unref(selectedRowKeys),
      onChange: onSelectChange,
      hideDefaultSelections: true,
      selections: null,
    };
  }),
  user = computed(() => useStore().getters['user/user']),
  searchInput = ref(''),
  route = useRoute(),
  initialParams = {
    page: 1,
    'per-page': 10,
    sort: '',
  },
  queryParams = reactive({ ...initialParams }),
  columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      fixed: 'left',
      width: 80,
      slots: {customRender: 'id'},
    },
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: 170,
      slots: {customRender: 'name'},
    },
    {
      title: 'Филиал',
      dataIndex: 'store_id',
      key: 'store_id',
      fixed: 'left',
      width: 220,
      slots: {customRender: 'store_id'},
    },
    {
      title: 'Коммиссия (счет)',
      dataIndex: 'bill_commission',
      key: 'bill_commission',
      width: 140,
      slots: {customRender: 'bill_commission'},
    },
    {
      title: 'Коммиссия (клиент)',
      dataIndex: 'bill_commission_up',
      key: 'bill_commission_up',
      width: 140,
      slots: {customRender: 'bill_commission_up'},
    },
    {
      title: 'Коммиссия (payze)',
      dataIndex: 'payze_commission',
      key: 'payze_commission',
      width: 140,
      slots: {customRender: 'payze_commission'},
    },
    {
      title: 'Тип',
      dataIndex: 'type',
      key: 'type',
      width: 200,
      slots: {customRender: 'type'},
    },
    // {
    //   title: 'Фискализация',
    //   dataIndex: 'fiscalization',
    //   key: 'fiscalization',
    //   width: 140,
    //   slots: {customRender: 'fiscalization'},
    // },
    {
      title: 'Device ID',
      dataIndex: 'device_id',
      key: 'device_id',
      sorter: true,
      width: 250,
      slots: {customRender: 'device_id'},
    },
    {
      title: 'Стр. быстрой оплаты',
      dataIndex: 'qr',
      key: 'qr',
      width: 200,
      slots: {customRender: 'qr'},
    },
    {
      title: 'Стр. официантов',
      dataIndex: 'store_qr',
      key: 'store_qr',
      width: 200,
      slots: {customRender: 'store_qr'},
    },
    {
      title: 'Добавлен',
      dataIndex: 'added_on',
      key: 'added_on',
      width: 180,
      slots: {customRender: 'date'},
    },
    {
      title: 'Редактирован',
      dataIndex: 'updated_on',
      key: 'updated_on',
      width: 180,
      slots: {customRender: 'updated'},
    },
    {
      key: 'action',
      fixed: 'right',
      width: 70,
      slots: {customRender: 'action'},
    },
  ],
  formattedDatetime = (date) => {
    date = date.replace(/ /g, "T")
    let d = new Date(date)
    const timeFormatter = new Intl.DateTimeFormat('ru-RU', {
      timeStyle: 'medium',
    })
    const dateFormatter = new Intl.DateTimeFormat('ru-RU')
    return dateFormatter.format(d) + ' ' + timeFormatter.format(d)
  },
  updateParams = params => {
    Object.assign(queryParams, params)
    Object.keys(params).forEach((queryParam) => {
      switch (queryParam) {
        case 'brand_id':
          brandId.value = params.brand_id;
          break;
        case 'store_id':
          storeId.value = params.store_id;
          break;
        case 'search':
          searchInput.value = params.search;
          break;
      }
    })
  },
  tableChange = (pag, filters, sorter) => {
    if (typeof sorter.order == "string") {
      let sign = ''
      if (sorter.order === 'descend')
        sign = '-'
      updateParams({sort: sign + sorter.field})
    }
  },
  updateColumn = (id, name, column, value) => {
    if (!['admin', 'brandmanager'].includes(user.value.role))
      return false

    let data = {};
    data['name'] = name;
    data[column] = value;
    return apiClient.put(`/terminal/update?terminal=${id}`, data).then(response => {
      if (response) {
        message.success('Касса успешно обновлена')
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      reload()
    });
  },
  updateStore = (value, terminal) => {
    if (!['admin', 'brandmanager'].includes(user.value.role))
      return;
    editStoreLoading.value = terminal.id;
    updateColumn(terminal.id, terminal.name, 'store_id', value)
      .finally(() => {
        editStoreData.value = null
        editStoreId.value = 0
        editStoreLoading.value = 0
      });
  },
  updateType = (value, terminal) => {
    if (user.value.role !== 'admin')
      return;
    editTypeLoading.value = terminal.id;
    updateColumn(terminal.id, terminal.name, 'type', value)
      .finally(() => {
        editType.value = null
        editTypeId.value = 0
        editTypeLoading.value = 0;
      });
  },
  clearSelection = () => {
    selectedRowKeys.value = []
  },
  selectAll = () => {
    const params = {...queryParams}
    return apiClient.get('/terminal/ids', { params }).then(({data}) => {
      if (data['data']) {
        selectedRowKeys.value = data['data']
      }
    })
  },
  multipleUpdateModal = () => {
    multipleEditModalKey.value++
    multipleEditModalVisible.value = true;
  },
  hideMultipleEditModal = () => {
    setTimeout(() => {
      multipleEditModalVisible.value = false
    }, 100)
  },
  getTypeName = (name) => {
    switch (name) {
      case 'cashbox':
        return 'Касса';
      case 'delivery':
        return 'Доставка'
      case 'static':
        return 'Статичный'
      case 'aggregator':
        return 'Агрегатор'
      default:
        return 'Не указано';
    }
  },
  showDeleteModal = id => {
    deleteModal.value = true;
    deleteId.value = id;
  },
  hideDeleteModal = () => {
    deleteModal.value = false;
    deleteId.value = 0
  },
  deleteTerminal = () => {
    return apiClient.delete(`terminal/delete?id=${deleteId.value}`)
      .then(response => {
        if (response) {
          message.success('Касса успешно удалена')
        }
      }).catch(() => {
        // console.log(err)
      }).finally(() => {
        hideDeleteModal()
        reload()
      });
  },
  addTerminal = () => {
    return apiClient.post(`/terminal`, {
      store_id: storeId.value,
    }).then(response => {
      if (response) {
        notification.success({
          message: 'Касса добавлена',
        })
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      reload()
    })
  },
  search = (e) => {
    if (e.target.value.length > 0 && e.target.value.length < 3)
      return;
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(() => {
      updateParams({search: e.target.value})
    }, 800);
  },
  searchBrand = (e) => {
    updateParams({brand_id: e})
  },
  searchStore = (e) => {
    updateParams({store_id: e})
  },
  getData = (params) => {
    loading.value = true

    if (route.params.brand && !params.brand_id) {
      params['brand_id'] = route.params.brand;
      brandId.value = route.params.brand
    }
    if (route.params.store && !params.store_id) {
      params['store_id'] = route.params.store;
      storeId.value = route.params.store
    }

    return apiClient.get(`/terminal`, { params }).then(({data}) => {
      dataSource.value = data.data.items
      brands.value = data.data.brands
      stores.value = data.data.stores
      total.value = data.data.total
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      loading.value = false
      store.commit('CHANGE_FILTER', { page: 'terminal', value: params })
    });
  },
  onEditNameId = (id) => {
    if (!['admin', 'brandmanager'].includes(user.value.role))
      return false

    editNameId.value = id;
    nextTick(() => inputName.value?.focus());
  },
  onEditStore = (id, store_id) => {
    editStoreOptionsLoading.value = id
    apiClient.get('terminal/store-ids?id=' + store_id).then(({ data }) => {
      editStoreOptions.value = data.data
    }).finally(() => {
      editStoreOptionsLoading.value = 0
    })
    editStoreId.value = id
    editStoreData.value = store_id.toString()
  },
  onEditType = (id, type) => {
    editTypeId.value = id;
    editType.value = type
  },
  onEditDeviceId = (id) => {
    if (!['admin', 'brandmanager'].includes(user.value.role))
      return;
    editDeviceId.value = id;
    nextTick(() => inputDevice.value?.focus());
  },
  onEditBillCommmissionId = (id) => {
    if (user.value.role !== 'admin')
      return;
    editBillCommissionId.value = id;
    nextTick(() => inputBillCommission.value?.focus());
  },
  onEditPayzeCommmissionId = (id) => {
    if (user.value.role !== 'admin')
      return;
    editPayzeCommissionId.value = id;
    nextTick(() => inputPayzeCommission.value?.focus());
  },
  onEditBillCommmissionUpId = (id) => {
    if (user.value.role !== 'admin')
      return;
    editBillCommissionUpId.value = id;
    nextTick(() => inputBillCommissionUp.value?.focus());
  },
  reload = () => {
    getData({
      ...queryParams,
    })
  },
  createQr = (ids) => {
    return apiClient.post('/terminal/create-qr', { ids }).then(() => {
      // console.log(data)
    }).finally(() => {
      reload()
    })
  },
  createStoreQr = (ids) => {
    return apiClient.post('/terminal/create-store-qr', { ids }).then(() => {
      // console.log(data)
    }).finally(() => {
      reload()
    })
  };
const getStateParams = () => {
  let stateParams = {}
  if (filters.value.terminal) {
    stateParams = filters.value.terminal
    Object.keys(stateParams).forEach(paramKey => {
      let _params = {}
      _params[paramKey] = stateParams[paramKey]
      updateParams(_params)
    })
  }
}

onMounted(() => {
  // if (user.value.role !== 'admin')
  //   delete columns[0]

  getStateParams()

  if (route.query.brand) {
    // brandId.value = route.query.brand_id
    // storeId.value = route.query.brand_id
    route.params.brand = route.query.brand_id
    updateParams({brand_id: route.query.brand_id})
    return;
  }

  getData({
    ...queryParams,
  })
})

watch(selectedRowKeys, (value) => {
  showSelectAllButton.value = value.length === queryParams['per-page']
})

watch(queryParams, () => {
  router.push({
    name: 'terminal',
    params: {
      brand: route.params.brand,
      store: route.params.store,
    },
    query: {
      ...route.query,
      ...queryParams,
    },
  })
  getData({
    ...route.query,
    ...queryParams,
  })
})

const query = {...toRefs(queryParams)}

</script>
<style scoped>
@import "./index.css";
</style>
