export default function Terminal(attrs) {
  this.name = attrs.name ? attrs.name : ''
  this.store_id = attrs.store_id ? attrs.store_id : null
  this.fiscalization = attrs.fiscalization ? attrs.fiscalization : 0
  this.type = attrs.type ? attrs.type : 'cashbox'
  this.bill_commission = attrs.bill_commission ? attrs.bill_commission : 1.5
  this.bill_commission_up = attrs.bill_commission_up ? attrs.bill_commission_up : 0
  this.payze_commission = attrs.payze_commission ? attrs.payze_commission : 3
  this.device_id = attrs.device_id ? attrs.device_id : null
}
