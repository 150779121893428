<template>
  <a-modal
    class="terminal-edit-modal"
    title="Редактирование кассы"
    :visible="modalVisible"
    :closable="true"
    :confirm-loading="!(!loading && !confirmLoading)"
    @ok="update"
    @cancel="resetForm"
  >
    <div v-show="!(!loading && !confirmLoading)" class="w-100 py-5 my-5 text-center">
      <a-spin tip="Загрузка..."></a-spin>
    </div>
    <a-form v-show="!loading && !confirmLoading" v-model="terminal" @submit.prevent="update">
      <div class="col-md-12">
        <a-form-item label="Название кассы">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-10">
                <div class="row">
                  <a-input
                    :disabled="!editName"
                    placeholder="Введите название кассы"
                    v-model:value="terminal.name"
                  />
                </div>
              </div>
              <div class="col-md-2">
                <div class="row justify-content-end">
                  <a-button @click="editName = !editName" type="link">
                    <CloseCircleOutlined v-if="editName" />
                    <EditOutlined v-else />
                  </a-button>
                </div>
              </div>
            </div>
          </div>
        </a-form-item>
        <a-form-item label="Филиал">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-10">
                <div class="row">
                  <a-select
                    :disabled="!editStore"
                    v-model:value="terminal.store_id"
                    :options="stores"
                    :option-filter-prop="'label'"
                    placeholder="Выберите филиал"
                    style="width: 100%"
                    show-search
                    allow-clear
                  />
                </div>
              </div>
              <div class="col-md-2">
                <div class="row justify-content-end">
                  <a-button @click="editStore = !editStore" type="link">
                    <CloseCircleOutlined v-if="editStore" />
                    <EditOutlined v-else />
                  </a-button>
                </div>
              </div>
            </div>
          </div>
        </a-form-item>
        <a-form-item label="Коммиссия (счет)">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-10">
                <div class="row">
                  <a-input
                    :disabled="!editBillCommission"
                    placeholder="Введите коммиссию"
                    v-model:value="terminal.bill_commission"
                  />
                </div>
              </div>
              <div class="col-md-2">
                <div class="row justify-content-end">
                  <a-button @click="editBillCommission = !editBillCommission" type="link">
                    <CloseCircleOutlined v-if="editBillCommission" />
                    <EditOutlined v-else />
                  </a-button>
                </div>
              </div>
            </div>
          </div>
        </a-form-item>
        <a-form-item label="Коммиссия (клиент)">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-10">
                <div class="row">
                  <a-input
                    :disabled="!editBillCommissionUp"
                    placeholder="Введите коммиссию"
                    v-model:value="terminal.bill_commission_up"
                  />
                </div>
              </div>
              <div class="col-md-2">
                <div class="row justify-content-end">
                  <a-button @click="editBillCommissionUp = !editBillCommissionUp" type="link">
                    <CloseCircleOutlined v-if="editBillCommissionUp" />
                    <EditOutlined v-else />
                  </a-button>
                </div>
              </div>
            </div>
          </div>
        </a-form-item>
        <a-form-item label="Коммиссия (payze)">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-10">
                <div class="row">
                  <a-input
                    :disabled="!editPayzeCommission"
                    placeholder="Введите коммиссию"
                    v-model:value="terminal.payze_commission"
                  />
                </div>
              </div>
              <div class="col-md-2">
                <div class="row justify-content-end">
                  <a-button @click="editPayzeCommission = !editPayzeCommission" type="link">
                    <CloseCircleOutlined v-if="editPayzeCommission" />
                    <EditOutlined v-else />
                  </a-button>
                </div>
              </div>
            </div>
          </div>
        </a-form-item>
        <a-form-item label="Тип">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-10">
                <div class="row">
                  <a-select
                    :disabled="!editType"
                    v-model:value="terminal.type"
                    style="width: 100%"
                  >
                    <a-select-option
                      :value="null"
                    >Не указано</a-select-option>
                    <a-select-option value="cashbox">{{ getTypeName('cashbox') }}</a-select-option>
                    <a-select-option value="delivery">{{ getTypeName('delivery') }}</a-select-option>
                    <a-select-option value="static">{{ getTypeName('static') }}</a-select-option>
                    <a-select-option value="aggregator">{{ getTypeName('aggregator') }}</a-select-option>
                  </a-select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="row justify-content-end">
                  <a-button @click="editType = !editType" type="link">
                    <CloseCircleOutlined v-if="editType" />
                    <EditOutlined v-else />
                  </a-button>
                </div>
              </div>
            </div>
          </div>
        </a-form-item>
        <a-form-item label="Фискализация">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-10">
                <div class="row">
                  <a-select
                    :disabled="!editFiscalization"
                    v-model:value="terminal.fiscalization"
                    style="width: 100%"
                  >
                    <a-select-option :value="0">Выкл</a-select-option>
                    <a-select-option :value="1">Вкл</a-select-option>
                  </a-select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="row justify-content-end">
                  <a-button @click="editFiscalization = !editFiscalization" type="link">
                    <CloseCircleOutlined v-if="editFiscalization" />
                    <EditOutlined v-else />
                  </a-button>
                </div>
              </div>
            </div>
          </div>
        </a-form-item>
        <a-form-item label="Device ID">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-10">
                <div class="row">
                  <a-input
                    :disabled="!editDevice"
                    placeholder="Введите DeviceID"
                    v-model:value="terminal.device_id"
                  />
                </div>
              </div>
              <div class="col-md-2">
                <div class="row justify-content-end">
                  <a-button @click="editDevice = !editDevice" type="link">
                    <CloseCircleOutlined v-if="editDevice" />
                    <EditOutlined v-else />
                  </a-button>
                </div>
              </div>
            </div>
          </div>
        </a-form-item>
      </div>
    </a-form>
  </a-modal>
</template>

<script setup>
import {
  EditOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons-vue';
import { reactive, ref } from 'vue';
import apiClient from "@/services/axios";
import {notification} from "ant-design-vue";
import Terminal from "@/models/Terminal";

const props = defineProps({
  terminalIds: {
    type: Array,
    default: null,
  },
  stores: {
    type: Array,
    default: null,
  },
})

const emit = defineEmits(['hide-modal', 'reload']);

let editName = ref(false),
  editStore = ref(false),
  editBillCommission = ref(false),
  editBillCommissionUp = ref(false),
  editPayzeCommission = ref(false),
  editType = ref(false),
  editFiscalization = ref(false),
  editDevice = ref(false),
  loading = ref(false),
  confirmLoading = ref(false),
  modalVisible = ref(true),
  companyName = ref(null),
  phone = ref(''),
  codeData = ref([]),
  spicModel = ref([]),
  codeModel = ref([]),
  terminal = reactive(new Terminal({}));

const
  resetForm = () => {
    Object.assign(terminal, new Terminal({}));
    modalVisible.value = false;
    emit('hide-modal')
  },
  getTypeName = (name) => {
    switch (name) {
      case 'cashbox':
        return 'Касса';
      case 'delivery':
        return 'Доставка'
      case 'static':
        return 'Статичный'
      case 'aggregator':
        return 'Агрегатор'
      default:
        return 'Не указано';
    }
  },
  update = () => {
    confirmLoading.value = true;

    let model = {}
    if (editName.value) model.name = terminal['name']
    if (editStore.value) model.store_id = terminal['store_id']
    if (editBillCommission.value) model.bill_commission = terminal['bill_commission']
    if (editBillCommissionUp.value) model.bill_commission_up = terminal['bill_commission_up']
    if (editPayzeCommission.value) model.payze_commission = terminal['payze_commission']
    if (editType.value) model.type = terminal['type']
    if (editFiscalization.value) model.fiscalization = terminal['fiscalization']
    if (editDevice.value) model.device_id = terminal['device_id']

    return apiClient.put(`/terminal/update-all`, {
      ...model,
      ids: props.terminalIds,
    }).then(response => {
      if (response) {
        resetForm()
        if (response.data.data) {
          response.data.data.forEach((item) => {
            notification.warning({
              message: `ID: ${item.id}`,
              description: item.details,
            })
          })
        }
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      confirmLoading.value = false;
      modalVisible.value = false;
      emit('reload')
      emit('hide-modal')
    })
  };

</script>

<style scoped>
@import "./edit.css";
</style>
